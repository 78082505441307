/** @jsx jsx */
import { jsx, Box, Card, Image } from "theme-ui";
import { Link } from "gatsby";
import AppIcons from "sites-common/utils/icons";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectorSrcmProfile } from "gatsby-plugin-hfn-profile/state/selectors";
// import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
// import { get } from "sites-common/utils/lodash";
// import useGetRoleUsers from "sites-common/utils/useGetRoleUsers";

const MenuItems = ({ items }) => (
  <Box
    p="4"
    sx={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: 4,
    }}
  >
    {items.map(
      ({ label, subLabel, to, icon, allow = true }) =>
        allow && (
          <Card
            key={label}
            sx={{
              width: "300px",
              height: "300px",
              boxShadow: "0px 3px 6px #00000029",
              opacity: 1,
              textAlign: "center",
              cursor: "pointer",
              borderRadius: "2px",
              border: "2px solid ",
              borderColor: "background",
              mb: 2,
              "&:hover": {
                border: "2px solid",
                borderColor: "primary",
              },
              backgroundColor: "background",
            }}
          >
            <Link to={to} sx={{ textDecoration: "none" }}>
              <Box
                sx={{
                  letterSpacing: " 0.24px",
                  color: "primary",
                  opacity: 1,
                  fontWeight: "bold",
                  padding: "20px 10px",
                  boxShadow: "0px 25px 0px -24px #bebdbd",
                }}
              >
                {label}
              </Box>
              <Box
                sx={{
                  pt: 4,
                }}
              >
                <Image
                  src={icon}
                  alt={label}
                  sx={{ height: "130px", width: "130px" }}
                />
              </Box>
              <Box
                sx={{
                  letterSpacing: " 0.24px",
                  color: "secondary",
                  opacity: 1,
                  px: "2",
                  py: "3",
                  fontSize: "14px",
                }}
              >
                {subLabel}
              </Box>
            </Link>
          </Card>
        )
    )}
  </Box>
);

MenuItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.node,
      allow: PropTypes.bool,
    })
  ).isRequired,
};

const Index = () => {
  // const { srcmProfile } = useAuth();
  // const { roles } = useGetRoleUsers();

  const srcmProfile = useSelector(selectorSrcmProfile);

  let rolesList;
  if (srcmProfile && srcmProfile.roles) {
    rolesList = [...srcmProfile.roles];
  }

  const menuItems = [
    {
      label: "Event Organisers",
      subLabel: "Events Organised by me >>",
      to: "/events-menu",
      icon: AppIcons.event_landing,
      // allow: get(srcmProfile, "isAtleastPractitioner")
    },
    {
      label: "Event Approvals",
      subLabel: "Events Approvals >>",
      to: "/approving-events",
      icon: AppIcons.approval_landing,
      // allow:
      //   get(srcmProfile, "isAtleastPractitioner")
    },
    {
      label: "Connect Events Admin",
      subLabel: "Master Data >>",
      to: "/events-heartsappadmin",
      icon: AppIcons.event_landing,
      allow: rolesList && rolesList.includes("connect_admin"),
    },
    {
      label: "Info Center Access",
      subLabel: "PNRs & Payments >>",
      to: "/pnr-search",
      icon: AppIcons.event_landing,
      allow:
        rolesList &&
        (rolesList.includes("info_center") ||
          rolesList.includes("volunteer_access")),
    },
  ];

  return (
    <div>
      <MenuItems items={menuItems} />
    </div>
  );
};

export default Index;
